const PRICE_UP_COLOR = '#10ba68'
const PRICE_DOWN_COLOR = '#FF5B5A'

const overrides = {
  'timeScale.rightOffset': 1,
  'paneProperties.topMargin': 20,
  'paneProperties.legendProperties.showBarChange': false,
  'paneProperties.legendProperties.showSeriesOHLC': false,
  'paneProperties.legendProperties.showStudyArguments': false,
  'mainSeriesProperties.candleStyle.upColor': PRICE_UP_COLOR,
  'mainSeriesProperties.candleStyle.downColor': PRICE_DOWN_COLOR,
  'mainSeriesProperties.candleStyle.borderUpColor': PRICE_UP_COLOR,
  'mainSeriesProperties.candleStyle.borderDownColor': PRICE_DOWN_COLOR,
  'mainSeriesProperties.candleStyle.wickUpColor': PRICE_UP_COLOR,
  'mainSeriesProperties.candleStyle.wickDownColor': PRICE_DOWN_COLOR
}
const tradingViewOptions = {
  updateFrequency: 4 * 1000,
  updateFrequencyDelay: 24 * 60 * 60 * 1000,
  locale: {
    en: 'en',
    es: 'es',
    fr: 'fr',
    indo: 'id_ID',
    it: 'it',
    'ja-JP': 'ja',
    'ko-KR': 'ko',
    my: 'ms_MY',
    pt: 'pt',
    th: 'th',
    'tr-TR': 'tr',
    vn: 'vi',
    'zh-CN': 'zh',
    'zh-TW': 'zh_TW'
  },
  disabledFeatures: [
    'border_around_the_chart',
    'caption_buttons_text_if_possible',
    'context_menus',
    'go_to_date',
    'volume_force_overlay',
    'symbol_search_hot_key', // 商品搜索热键
    'header_symbol_search', // 标题符号搜索
    'header_compare',
    'header_settings',
    'header_screenshot',
    'header_fullscreen_button'
  ],
  enabledFeatures: [
    'header_interval_dialog_button',
    'dont_show_boolean_study_arguments',
    'hide_last_na_study_output',
    'hide_left_toolbar_by_default',
    'side_toolbar_in_fullscreen_mode'
  ],
  defaultInterval: '5',
  timeFrames: [
    { text: '1d', resolution: '15' },
    { text: '5d', resolution: '15' },
    { text: '1m', resolution: '240' },
    { text: '1y', resolution: '1D' }
  ],
  day: {
    theme: 'Light',
    loadingScreen: { backgroundColor: 'white' },
    defaultOverride: {
      'paneProperties.background': 'white',
      'paneProperties.backgroundType': 'solid',
      'paneProperties.vertGridProperties.color': '#f2f2f2',
      'paneProperties.horzGridProperties.color': '#f2f2f2',
      'paneProperties.crossHairProperties.color': '#8698AA',
      'scalesProperties.lineColor': 'rgba(255, 255, 255, 0)',
      'scalesProperties.textColor': '#8698AA'
    },
    overrides
  },
  night: {
    theme: 'Dark',
    loadingScreen: {
      backgroundColor: '#1E2C4C'
    },
    defaultOverride: {
      'paneProperties.background': '#1E2C4C',
      'paneProperties.backgroundType': 'solid',
      'paneProperties.vertGridProperties.color': '#334573',
      'paneProperties.horzGridProperties.color': '#334573',
      'paneProperties.crossHairProperties.color': 'white',
      'scalesProperties.lineColor': 'rgba(255, 255, 255, 0)',
      'scalesProperties.textColor': '#CACED6'
    },
    overrides
  }
}

export { tradingViewOptions }
