import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// TODO: Not quite sure if need this kind of authentication
// import WithAuth from "../../hoc/WithAuth";

import ResetPass from "../../components/ResetPass";
import Privacy from "../../components/Privacy";
import Conditions from "../../components/Conditions";
import GeneralConfirm from "../../components/Confirmation";
import Charts from "../../components/Charts";
import NoMatch from "../../components/NoMatch";

import PATH from "../../utils/pathConst";
import styles from "./styles.module.scss";

function App() {
  return (
    <div className={styles["container--outer"]}>
      <Router>
        <Switch>
          {/* <WithAuth> */}
          <Route path={PATH.PRIVACY} component={Privacy} />
          <Route path={PATH.CONDITIONS} component={Conditions} />
          <Route path={PATH.RESETPASS} component={ResetPass} />
          <Route path={PATH.CHARTS} component={Charts} />
          <Route
            path={`${PATH.CONFIRMATION}/:type`}
            component={GeneralConfirm}
          />
          <Route path="*" component={NoMatch} />
          {/* </WithAuth> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
