import { React, useState } from "react";
import { useForm } from "react-hook-form";

import Logo from '../../assets/imgs/logo.png';
import twiceSha256Hash from '../../utils/general';
import apiPath from "../../utils/apiConst";

import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";

export default function ResetPass(props) {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [fetchFinish, setFechFinish] = useState(false);
  const [send, setSend] = useState(false);
  
  const verifyCode = props.location.search && props.location.search.split('&')[1].split('=')[1];
  if(!verifyCode){
    history.push("/");
  }
  
  const onSubmit = data => {
    setSend(true);
    
    // data would be {newpassword: 'Aa12345678' , confirmpassword: 'Aa12345678'}
    const shaPass = twiceSha256Hash(data.newpassword);
    const requestData = {
      pass: shaPass,
      code: verifyCode
    }
    
    const apiEndpoint = process.env.NODE_ENV === 'production' 
      ? `${apiPath.BE_DOMAIN}${apiPath.RESET_PASSAPI_ENDPOINT}`
      : apiPath.RESET_PASSAPI_ENDPOINT;
    // NOTE: below just to test if third-party API can be called in local dev environment
    // fetch('https://api.openweathermap.org/data/2.5/weather?q=taipei&appid=dec07f58bebbba3a528a80f2353b2f3f', {
    //   method: 'GET'
    // }).then(res => {
    //   console.log(res.json());
    // })

    fetch(apiEndpoint, {
      method: 'POST',
      body: JSON.stringify(requestData),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }).then(res => {
      return res.json();
    }).then(resObj => {
      console.info('res like: ', resObj);
      if(resObj.data.code === 5534){
        setFetchSuccess(true);
      }else{
        setFetchSuccess(false);
      }
      setSend(false);
      setFechFinish(true);
    }).catch(error => {
      console.error('There has been a problem with your fetch operation: ', error.message);
      setFetchSuccess(false);
      setSend(false);
      setFechFinish(true);
    });
  }

  const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,}$/i;
  const confirmPassIsValid = data => {
    const newPass = document.getElementsByTagName('input')[0].value;
    if(!newPass || newPass !== data){
      return false;
    }
    return true;
  }

  const renderSubmitButton = () => {
    return (
      <div className={styles['input--wrapper']}>
        <input type="submit" className={styles['submit--button']} disabled={send} value="Confirm"/>
      </div>
    );
  }

  const renderFetchFail = () => {
    return (
      <div className={styles['warn--wrapper']}>
        <span>Request fail please contact system admin, thanks!</span>
      </div>
    );
  }

  const renderFetchSuccess = () => {
    return (
      <div className={styles['warn--wrapper']}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
          <path d="M34.912 50.75l10.89 10.125L67 36.75" fill="none" strokeWidth="6" stroke="green" />
        </svg>
      </div>
    );
  }
  
  return (
    <div className={styles.resetpage}>
      <div className={styles['logo--wrapper']}>
        <img src={Logo} className={styles['logo']} alt="logo" />
      </div>
      <div className={styles.container}>
        <div>
          <span>Reset password</span>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles['input--wrapper']}>
              <input 
                type="password"
                name="newpassword"
                ref={register({ required: true, pattern: passPattern })}
                placeholder="New Password with 8 ~ 16 Alphanumeric characters"/>
            </div>
            <div className={styles['warn--wrapper']}>
              {errors.newpassword && errors.newpassword.type === "required" && <span>This field is required</span>}
              {errors.newpassword && errors.newpassword.type === "pattern" && <span>Please follow the password rule</span>}
            </div>
            
            <div className={styles['input--wrapper']}>
              <input
                type="password"
                name="confirmpassword"
                ref={register({ required: true, validate: confirmPassIsValid })}
                placeholder="Confirm password" />
            </div>
            <div className={styles['warn--wrapper']}>
              {errors.confirmpassword && errors.confirmpassword.type === "required" && <span>This field is required</span>}
              {errors.confirmpassword && errors.confirmpassword.type === "validate" && <span>Confirm password not match</span>}
            </div>

            { (!fetchSuccess && fetchFinish) && renderFetchFail() }
            { (!fetchFinish || !fetchSuccess) && renderSubmitButton() }

            { (fetchFinish && fetchSuccess) && renderFetchSuccess() }
          </form>
        </div>
      </div>
    </div>
  );
};
