import React from "react";
import styles from "./styles.module.scss";

export default function ResetPass(props) {
  return (
    <div className={styles.container}>
      <div>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Welcome to the privacy policy of NOGLE (“Privacy Policy”).
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            When we mention “NOGLE”, “we”, “us” or “our” in this Privacy Policy,
            we are referring to Nogle (Singapore) Pte. Ltd., and its affiliates
            that are responsible for processing your personal data.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We respect your privacy and are committed to protecting your
            personal data. This Privacy Policy will inform you as to how we look
            after your personal data when you visit our website at www.nogle.sg
            (regardless of where you visit it from) or any other websites,
            pages, features, or content we own or operate and/or when you use
            our mobile app or any NOGLE application programming interface
            (collectively, the "Interface") or the services offered by the
            Interface ("Services").
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            NOGLE is committed to comply with the applicable laws related to the
            General Data Protection Regulation (EU) 2016/679 (“GDPR”). The
            Privacy Policy sets forth the basic principles by which NOGLE
            collects, retains, transfers, disposes and otherwise processes your
            personal data.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Terms used throughout this Privacy Policy shall have the meaning
            ascribed to them in the GDPR.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              ACCEPTANCE OF PRIVACY POLICY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            By accessing and using our Services, you signify acceptance to the
            terms of this Privacy Policy. We reserve the right to revise this
            Privacy Policy at any time to reflect changes in law or our personal
            data collection and use practices. If changes are made to this
            Privacy Policy, we will notify you by email, by posting on the
            Interface, or by means of a notice on our Services and you will be
            required to accept any updated privacy policy before you are able to
            use the Services.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            If you do not agree with or you are not comfortable with any aspect
            of this Privacy Policy, you should immediately discontinue access or
            use of our Interface and Services.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            If you have any questions about this Privacy Policy or our privacy
            practices, please contact us via "Support Center" link on the
            website,&nbsp;
          </span>
          <a href="https://www.nogle.sg">
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              https://www.nogle.sg
            </span>
          </a>
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            .
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              PURPOSE OF THIS PRIVACY POLICY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            This Privacy Policy aims to give you information on how we collect
            and process your personal data through your use of the Interface and
            Services. Our Interface and Services are not intended for children
            and we do not knowingly collect data relating to children.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            It is of great importance that you read this Privacy Policy together
            with any other privacy policy or fair processing policy we may
            provide on specific occasions when we are collecting or processing
            personal data about you so that you are fully aware of how and why
            we are using your personal data. This Privacy Policy supplements
            other notices and privacy policies, if any, and is not intended to
            override them.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              YOUR DUTY TO INFORM US OF CHANGES
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              THIRD-PARTY LINKS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Our Interface and Services may include links to third-party
            websites, plug-ins and applications. Clicking on those links or
            enabling those connections may allow third parties to collect or
            share data about you. We do not control these third-party websites
            and are not responsible for their privacy statements. When you leave
            our Interface or Services, we encourage you to read the privacy
            policy of every website you visit.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              THE DATA WE COLLECT ABOUT YOU
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together as follows:
          </span>
        </p>
        <ul style={{ marginBottom: "0cm" }} type="disc">
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Identity Data includes first name, maiden name, last name,
              username or similar identifier, marital status, title, date of
              birth, age, nationality, photographs, gender, job title, Tax ID
              number, passport number, driver’s license details, national
              identity card details, photograph identification cards, and/or
              visa information.
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Contact Data includes billing address, delivery address, email
              address and telephone numbers.
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Financial Data includes bank account, payment card primary account
              number (PAN), transaction history, trading data, and/or tax
              identification.
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Transaction Data includes details about payments to and from you
              and other details of Services you have purchased from us, such as
              the name of the recipient, your name, the amount, and/or
              timestamp.
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Technical Data includes internet protocol (IP) address, your login
              data, browser type and version, time zone setting and location,
              browser plug-in types and versions, operating system and platform,
              and other technology on the devices you use to access our
              Interface and Services.
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Profile Data includes your username and password, purchases or
              orders made by you, your interests, preferences, feedback and
              survey responses.
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Usage Data includes information provided to our support team,
              public social networking posts, authentication data, security
              questions, user ID, click-stream data and other data collected via
              cookies and similar technologies.
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Marketing and Communications Data includes your preferences in
              receiving marketing from us and our third parties and your
              communication preferences.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We also collect, use and share Aggregated Data such as statistical
            or demographic data for any purpose. Aggregated Data could be
            derived from your personal data but is not considered personal data
            in law as this data will not directly or indirectly reveal your
            identity. For example, we may aggregate your Usage Data to calculate
            the percentage of users accessing a specific website feature.
            However, if we combine or connect Aggregated Data with your personal
            data so that it can directly or indirectly identify you, we treat
            the combined data as personal data which will be used in accordance
            with this Privacy Policy.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We do not collect any Special Categories of Personal Data about you
            (this includes details about your race or ethnicity, religious or
            philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health, and
            genetic and biometric data).
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              IF YOU FAIL TO PROVIDE PERSONAL DATA
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you, and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            services). In this case, we may have to cancel a product or service
            you have with us but we will notify you if this is the case at the
            time.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              HOW IS YOUR PERSONAL DATA COLLECTED?
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We use different methods to collect data from and about you
            including through:
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Direct interactions. You may give us your Identity, Contact and
            Financial Data by filling in forms or by corresponding with us by
            post, phone, email or otherwise. This includes personal data you
            provide when you:
          </span>
        </p>
        <ul style={{ marginBottom: "0cm" }} type="disc">
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              apply for our Services;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              create an account on our Interface or Services;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              subscribe to our Services or publications;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              request marketing material or content to be sent to you;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              enter a competition, promotion or survey; or
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              give us feedback or contact us.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Automated technologies/interactions and third party sources. We may
            automatically collect your personal information as you interact with
            our Interface (such as browsing actions, patterns, hardware model,
            device ID, operating system version, web-browser software and your
            Internet Protocol address/MAC address/device identifier). We may
            also receive your personal data from various third parties and
            public sources as required or permitted by applicable law (such as
            public databases, credit bureaus, ID verification partners,
            resellers and channel partners, joint marketing partners, and social
            media platforms).
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              PURPOSES FOR WHICH WE USE YOUR PERSONAL DATA
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We will only use your personal data within the limits allowed by
            law. Most commonly, with reference to the types of legal basis
            specifically outlined in the GDPR, we will use your personal data
            where:
          </span>
        </p>
        <ul style={{ marginBottom: "0cm" }} type="disc">
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              we need to perform the contract we are about to enter into or have
              entered into with you;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              it is necessary for our legitimate interests (or those of a third
              party) and your interests and fundamental rights do not override
              those interests; or
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              we need to comply with a legal obligation.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Please see the below Glossary to find out more about the types of
            lawful basis that we will rely on to process your personal data.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We have set out below, in table format, a description of the ways we
            plan on using your personal data, and on what basis we do so. We
            have also identified what our legitimate interests are where
            appropriate.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Please note that we may process your personal data for more than one
            lawful ground depending on the specific purpose for which we are
            using your data. Please contact us if you need details about the
            specific legal ground we are relying on to process your personal
            data where more than one ground has been set out in the table below.
          </span>
        </p>
        <table
          style={{
            border: "none",
            width: "603.0pt",
            background: "white",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "35.0%",
                  border: "solid #CCCCCC 1.0pt",
                  padding: "3.0pt 9.0pt 3.0pt 9.0pt",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: "15.0pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                        color: "#4A4A4A",
                      }}
                    >
                      Purpose/Activity
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "30.0%",
                  border: "solid #CCCCCC 1.0pt",
                  borderLeft: "none",
                  padding: "3.0pt 9.0pt 3.0pt 9.0pt",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: "15.0pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                        color: "#4A4A4A",
                      }}
                    >
                      Type of personal data
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "35.0%",
                  border: "solid #CCCCCC 1.0pt",
                  borderLeft: "none",
                  padding: "3.0pt 9.0pt 3.0pt 9.0pt",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: "15.0pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                        color: "#4A4A4A",
                      }}
                    >
                      Lawful basis for processing, including basis of legitimate
                      interest
                    </span>
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: "15.0pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To register you as a new user
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Identity
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Financial
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Technical
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Profile
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Marketing and Communications
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    Performance of a contract with you
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To deliver the Services including:
                  </span>
                </p>
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Manage transactions, payments, fees and charges
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Collect and recover fees owed to us
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Identity
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Financial
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Transaction
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Technical
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Usage
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Marketing and Communications
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Performance of a contract with you
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Necessary for our legitimate interests (to recover debts
                      due to us)
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To deliver the Services including:
                  </span>
                </p>
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Notifying you about changes to our terms or Privacy Policy
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Asking you to leave a review or take a survey
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Identity
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Profile
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Marketing and Communications
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Performance of a contract with you
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Necessary to comply with a legal obligation
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Necessary for our legitimate interests (to keep our
                      records updated and to study how customers use our
                      Services)
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To enable you to partake in a prize draw, competition or
                    complete a survey
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Identity
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Profile
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Usage
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Marketing and Communications
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Performance of a contract with you
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Necessary for our legitimate interests (to study how
                      customers use our Services, to develop them and grow our
                      business)
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To administer and protect our business, this Interface and
                    the Services (including troubleshooting, data analysis,
                    testing, system maintenance, support, reporting and hosting
                    of data)
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Identity
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Technical
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Usage
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Performance of a contract with you
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Necessary for our legitimate interests (for running our
                      business, provision of administration and IT services,
                      network security, to prevent fraud and in the context of a
                      business reorganisation or group restructuring exercise)
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Necessary to comply with a legal obligation
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To deliver relevant Interface and Services content and
                    advertisements to you and measure or understand the
                    effectiveness of the advertising we serve to you
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Identity
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Profile
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Usage
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Marketing and Communications
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Technical
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    Necessary for our legitimate interests (to study how
                    customers use our Services, to develop them, to grow our
                    business and to inform our marketing strategy)
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To use data analytics to improve our Interface, Services,
                    marketing, customer relationships and experiences
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Technical
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Profile
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Usage
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    Necessary for our legitimate interests (to define types of
                    users for our Services, to keep our website updated and
                    relevant, to develop our business and to inform our
                    marketing strategy)
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To identify or verify you in order to comply with applicable
                    laws such as anti-money laundering and terrorist financing
                    laws
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Identity
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Profile
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Usage
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Technical
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Financial
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Transaction
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Performance of a contract with you
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Necessary to comply with a legal obligation
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderLeft: "1pt solid rgb(204, 204, 204)",
                  borderImage: "initial",
                  borderTop: "none",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    To make suggestions and recommendations to you about
                    Services that may be of interest to you
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <ul style={{ marginBottom: "0cm" }} type="disc">
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Identity
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Contact
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Technical
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Usage
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Profile
                    </span>
                  </li>
                  <li
                    style={{
                      margin: "0cm",
                      marginBottom: ".0001pt",
                      fontSize: "16px",
                      fontFamily: '"Calibri",sans-serif',
                      color: "#4A4A4A",
                      textAlign: "justify",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: '"BTSE",sans-serif',
                      }}
                    >
                      Marketing and Communications
                    </span>
                  </li>
                </ul>
              </td>
              <td
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "1pt solid rgb(204, 204, 204)",
                  borderRight: "1pt solid rgb(204, 204, 204)",
                  padding: "3pt 9pt",
                  verticalAlign: "top",
                }}
              >
                <p
                  style={{
                    margin: "0cm",
                    marginBottom: ".0001pt",
                    fontSize: "16px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: '"BTSE",sans-serif',
                      color: "#4A4A4A",
                    }}
                  >
                    Necessary for our legitimate interests (to develop our
                    Services and grow our business)
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              MARKETING
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We may use your personal data to form a view on what we think you
            may want or need, or what may be of interest to you with respect to
            the Interface or the provision of the Services. Based on your
            communication preferences, we may send you marketing communications
            to inform you about our events or our partner events; to deliver
            targeted marketing and advertising; and to provide you with
            promotional offers based on your communication preferences.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              INFORMATION SHARED WITH THIRD PARTIES
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            From time to time we may request your permission to allow us to
            share your personal data with third parties. We will only share your
            personal data with third parties who have a legitimate purpose for
            accessing it, particularly:
          </span>
        </p>
        <ul style={{ marginBottom: "0cm" }} type="disc">
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              We share your personal data with third party identity verification
              services in order to perform obligations under AML Laws,
              Anti-Bribery Laws, Sanctions Laws, CTF Laws, and other Applicable
              Laws;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              We share your personal data with service providers who provide
              consultancy, banking, legal, insurance and accounting services
              under contract and who help with parts of our business operations;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              We share your personal data with financial institutions with which
              we partner to process payments you have authorised;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              We may share your personal data with companies or other entities
              that we plan to merge with or be acquired by. Should such a
              combination occur, we will require that the new combined entity
              follow this Privacy Policy with respect to your personal data. You
              will receive prior notice of any change in applicable policies;
              and
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              We share your personal data with law enforcement, officials, or
              other third parties when we are compelled to do so by a subpoena,
              court order, or similar legal procedure, or when we believe in
              good faith that the disclosure of personal data is necessary to
              prevent physical harm or financial loss, to report suspected
              illegal activity or to investigate violations of our user
              agreement or any other applicable policies.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the Privacy Policy
            and applicable laws.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              OPTING OUT
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            You may opt out of having your personal data shared with third
            parties, or allowing us to use your personal data for any purpose
            that is incompatible with the purposes for which we originally
            collected it or subsequently obtained your authorisation. If you
            choose to do so, certain features of our Interface or Services may
            not be available to you.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              COOKIES
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            You can set your browser to refuse all or some browser cookies, or
            to alert you when websites set or access cookies. If you disable or
            refuse cookies, please note that some parts of this website may
            become inaccessible or not function properly. For more information
            about the cookies we use, please see our <u>Cookie Policy</u>.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              CHANGE OF PURPOSE
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              INTERNATIONAL TRANSFERS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Your personal data may be transferred, stored, and processed in any
            country in which we operate.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              Data Subjects of the European Economic Area (“EEA”) Only:
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Where we transfer your personal data outside of the EEA, this is
            done either on the basis that it is necessary for the performance of
            the contract between you and NOGLE, or that the transfer is subject
            to the European Commission’s model contracts for the transfer of
            personal data to third countries (i.e., the standard contractual
            clauses), pursuant to Decision 2004/915/EC and Decision 2010/87/EU
            as appropriate or, in the event that the transfer is to a US entity,
            we may transfer personal data if the transferee is part of the
            Privacy Shield which requires them to provide similar protection to
            personal data shared between Europe and the US.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              DATA SECURITY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              DATA RETENTION
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            We will only retain your personal data for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. We may retain your personal data for a
            longer period in the event of a complaint or if we reasonably
            believe there is a prospect of litigation in respect to our
            relationship with you.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            To determine the appropriate retention period for personal data, we
            consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other
            requirements.
          </span>
        </p>
        <ul style={{ marginBottom: "0cm" }} type="disc">
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Contact Information such as your name, email address and telephone
              number for marketing purposes is retained on an ongoing basis
              until you unsubscribe.
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              Content that you post on our Interface such as support desk
              comments, photographs, videos, blog posts, and other content may
              be kept indefinitely after you close your account for audit and
              crime prevention purposes.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              YOUR LEGAL RIGHTS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data, which include the right to:
          </span>
        </p>
        <ul style={{ marginBottom: "0cm" }} type="disc">
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              request access to your personal data (commonly known as a “data
              subject access request”). This enables you to receive a copy of
              the personal data that we hold about you and to check that we are
              lawfully processing it;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              request correction of the personal data that we hold about you.
              This enables you to have any incomplete or inaccurate data we hold
              about you corrected, though we may need to verify the accuracy of
              the new data you provide to us;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              request erasure of your personal data. This enables you to ask us
              to delete or remove personal data where there is no good reason
              for us continuing to process it. You also have the right to ask us
              to delete or remove your personal data where you have successfully
              exercised your right to object to processing (see below), where we
              may have processed your information unlawfully or where we are
              required to erase your personal data to comply with local law.
              Note, however, that we may not always be able to comply with your
              request of erasure for specific legal reasons (for example, for
              complying with the requirements of retaining certain personal data
              for a specific period of time under the Applicable Laws) which
              will be notified to you, if applicable, at the time of your
              request;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              object to processing of your personal data where we are relying on
              a legitimate interest (or those of a third party) and there is
              something about your particular situation which makes you want to
              object to processing on this ground as you feel it impacts on your
              fundamental rights and freedoms. You also have the right to object
              where we are processing your personal data for direct marketing
              purposes. In some cases, we may demonstrate that we have
              compelling legitimate grounds to process your information which
              override your rights and freedoms;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              request restriction of processing of your personal data. This
              enables you to ask us to suspend the processing of your personal
              data in the following scenarios:
            </span>
            <ul style={{ marginBottom: "0cm" }} type="circle">
              <li
                style={{
                  margin: "0cm",
                  marginBottom: ".0001pt",
                  fontSize: "16px",
                  fontFamily: '"Calibri",sans-serif',
                  color: "#4A4A4A",
                  textAlign: "justify",
                  background: "white",
                }}
              >
                <span
                  style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}
                >
                  If you want us to establish the data’s accuracy.
                </span>
              </li>
              <li
                style={{
                  margin: "0cm",
                  marginBottom: ".0001pt",
                  fontSize: "16px",
                  fontFamily: '"Calibri",sans-serif',
                  color: "#4A4A4A",
                  textAlign: "justify",
                  background: "white",
                }}
              >
                <span
                  style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}
                >
                  Where our use of the data is unlawful but you do not want us
                  to erase it.
                </span>
              </li>
              <li
                style={{
                  margin: "0cm",
                  marginBottom: ".0001pt",
                  fontSize: "16px",
                  fontFamily: '"Calibri",sans-serif',
                  color: "#4A4A4A",
                  textAlign: "justify",
                  background: "white",
                }}
              >
                <span
                  style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}
                >
                  Where you need us to hold the data even if we no longer
                  require it as you need it to establish, exercise or defend
                  legal claims.
                </span>
              </li>
              <li
                style={{
                  margin: "0cm",
                  marginBottom: ".0001pt",
                  fontSize: "16px",
                  fontFamily: '"Calibri",sans-serif',
                  color: "#4A4A4A",
                  textAlign: "justify",
                  background: "white",
                }}
              >
                <span
                  style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}
                >
                  You have objected to our use of your data but we need to
                  verify whether we have overriding legitimate grounds to use
                  it;
                </span>
              </li>
            </ul>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              request the transfer of your personal data to you or to a third
              party. We will provide to you, or a third party you have chosen,
              your personal data in a structured, commonly used,
              machine-readable format. Note that this right only applies to
              automated information which you initially provided consent for us
              to use or where we used the information to perform a contract with
              you;
            </span>
          </li>
          <li
            style={{
              margin: "0cm",
              marginBottom: ".0001pt",
              fontSize: "16px",
              fontFamily: '"Calibri",sans-serif',
              color: "#4A4A4A",
              textAlign: "justify",
              background: "white",
            }}
          >
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              withdraw consent at any time where we are relying on consent to
              process your personal data. However, this will not affect the
              lawfulness of any processing carried out before you withdraw your
              consent. If you withdraw your consent, we may not be able to
              provide certain Services to you. We will advise you if this is the
              case at the time you withdraw your consent.
            </span>
          </li>
        </ul>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              GLOSSARY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              LAWFUL BASIS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Legitimate Interest means the interest of our business in conducting
            and managing our business to enable us to give you the best
            service/product and the best and most secure experience. We make
            sure we consider and balance any potential impact on you (both
            positive and negative) and your rights before we process your
            personal data for our legitimate interests. We do not use your
            personal data for activities where our interests are overridden by
            the impact on you (unless we have your consent or are otherwise
            required or permitted to by law). You can obtain further information
            about how we assess our legitimate interests against any potential
            impact on you in respect of specific activities by contacting us.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Performance of Contract means processing your data where it is
            necessary for the performance of a contract to which you are a party
            or to take steps at your request before entering into such a
            contract.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            Comply with a legal obligation means processing your personal data
            where it is necessary for compliance with a legal obligation that we
            are subject to.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "24px",
                fontFamily: '"BTSE",sans-serif',
                color: "#4A4A4A",
              }}
            >
              CONTACT DETAILS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            If you have any questions about this Privacy Policy or our privacy
            practices, please contact us via "Support Center" link on the
            website,&nbsp;
          </span>
          <a href="https://www.nogle.sg">
            <span style={{ fontSize: "14px", fontFamily: '"BTSE",sans-serif' }}>
              https://www.nogle.sg
            </span>
          </a>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              fontFamily: '"BTSE",sans-serif',
              color: "#4A4A4A",
            }}
          >
            You have the right to make a complaint at any time to the
            Information and Data Protection Tribunal (“Tribunal”). We would,
            however, appreciate the chance to deal with your matter internally
            before approaching the Tribunal.
          </span>
        </p>
        <p
          style={{
            margin: "0cm",
            marginBottom: ".0001pt",
            fontSize: "16px",
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
      </div>
    </div>
  );
}
