import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import styles from "./styles.module.scss";

import { tradingViewOptions } from './tradingViewOptions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const Charts = props => {
  const { locale, id = 'BTCPFC-USD-0' } = useParams();
  const query = useQuery();

  useEffect(() => {
    // const theme = query.get('theme');
    const marketType = query.get('type');
    const isFutures = marketType === 'futures';

    const chartThemeOptions = tradingViewOptions.day;
    const datafeedApiUrl = isFutures
      ? '/futures/api/tradingView'
      : '/api/tradingView';

    new window.TradingView.widget({
      fullscreen: true,
      theme: chartThemeOptions.theme,
      symbol: id,
      interval: '5',
      container: 'tv_chart_container',
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        datafeedApiUrl,
        tradingViewOptions.updateFrequency
      ),
      library_path: '/charting_library/',
      locale: tradingViewOptions.locale[locale] || 'en',
      disabled_features: tradingViewOptions.disabledFeatures,
      enabled_features: tradingViewOptions.enabledFeatures,
      autosize: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      time_frames: tradingViewOptions.timeFrames,
      favorites: {
        intervals: ['5', '60', '1D'],
        chartTypes: ['Candles', 'Area']
      },
      overrides: {
        ...chartThemeOptions.defaultOverride,
        ...chartThemeOptions.overrides
      },
      loading_screen: chartThemeOptions.loadingScreen
    });
  });

  return (
    <div id="tv_chart_container" className={styles.container} />
  );
};

export default Charts;
