import React from "react";
import SuccessImg from '../../assets/imgs/success.png'
import AlertImg from '../../assets/imgs/alert.png'
import styles from "./styles.module.scss";
import Logo from '../../assets/imgs/logo.png';

export default function GeneralConfirm(props) {
  const { type } = props.match.params;
  const alertcode = props.location.search.split('&')[0].split('=')[1];

  const rednerMainContent = type => {
    switch(type){
      case 'verification':
        return (
          <div className={styles['content--wrapper']}>
            {
              alertcode === '5530' && <div className={styles['content--title']}>Congratulations!</div>
            }
            {/* <div className={styles['content--title']}>Congradulations</div> */}
            <div className={styles['content--description']}>
              {
                alertcode === '5530'
                  ? 'Thanks for confirming your email. You are now ready to trade on Nogle app.'
                  : 'Please contact customer service.'
              }
            </div>
          </div>
        );
      case 'withdraw':
        return (
          <div className={styles['content--wrapper']}>
            <div className={styles['content--description']}>
              {
                alertcode === '40057'
                  ? 'Your withdraw request has been confirmed and is being processed.'
                  : 'Please contact customer service.'
              }
            </div>
          </div>
        );
      case 'transfer':
        return (
          <div className={styles['content--wrapper']}>
            <div className={styles['content--description']}>
              { 
                alertcode === '40041'
                  ? 'Your transfer has been confirmed.'
                  : 'Please contact customer service.'
              }
            </div>
          </div>
        );
      default:
    }
  }
  
  return (
    <div className={styles.container}>
      <div className={styles['logo--wrapper']}>
        <img src={Logo} className={styles['logo']} alt="logo" />
      </div>
      <div className={styles['main--wrapper']}>
        <div className={styles['checkMark--wrapper']}>
          {
            ((alertcode === '40057' && type === 'withdraw') 
              || (alertcode === '40041' && type === 'transfer')
              || (alertcode === '5530' && type === 'verification'))
              ? <img src={SuccessImg} className={styles['res-img']} alt="success" />
              : <img src={AlertImg} className={styles['res-img']} alt="alert" />
          }
        </div>
        { rednerMainContent(type) }
      </div>
    </div>
  );
};
