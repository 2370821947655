import React from "react";

import styles from "./styles.module.scss";

const NoMatch = props => {
  return (
    <div className={styles.container}>404... Inavlid link</div>
  );
};

export default NoMatch;
