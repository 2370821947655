
const RESETPASS = '/*/email-sended';
const CONFIRMATION = '/*/confirmation';
const PRIVACY = '/*/privacy';
const CONDITIONS = '/*/termsandconditions';
const CHARTS = '/:locale/charts/:id?';

const PATH = {
  RESETPASS,
  CONFIRMATION,
  PRIVACY,
  CONDITIONS,
  CHARTS
};

export default PATH;
