import React from "react";
import styles from "./styles.module.scss";

export default function ResetPass(props) {
  return (
    <div className={styles.container}>
      <div>
        <p align="center">
            <strong>STANDARD TERMS AND CONDITIONS OF SERVICE</strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <h1>
            1. <u>INTRODUCTION</u>
            <u></u>
        </h1>
        <p></p>
        <h2>
            1.1 These Standard Terms and Conditions (“Conditions”) apply exclusively
            between:
        </h2>
        <h2>
            1.1.1 any person accessing and using the Services (as defined below) (the
            “User” or “you”); and;
        </h2>
        <h2>
            1.1.2 Nogle (Singapore) Pte. Ltd. (Registration No.: 201615461K), (“Nogle
            Singapore”) a company incorporated in Singapore, owning and operating the
            Nogle Singapore Platform. Nogle Singapore is operating with the Standard
            Payment Institution Licence issued by Monetary Authority of Singapore
            (“MAS”) pursuant to the Payment Services Act, specifically for:
        </h2>
        <h2>
            i) account issuance service;
        </h2>
        <h2>
            ii) e-money issuance service; and
        </h2>
        <h2>
            iii) digital payment token service.
        </h2>
        <h2>
            all above are Permitted Payment Services, (“Services”), as well as its
            Affiliate(s) (collectively, the “Group”).
        </h2>
        <h2>
            (each a “Party” and collectively, the “Parties”).
        </h2>
        <h2>
            1.2 The Group provides the Services through the website of Nogle Singapore,
            the Group’s mobile application or any Nogle Singapore application,
            programming or interface (the “Interface”).
        </h2>
        <h2>
            1.3 These Conditions shall prevail notwithstanding any amendments by the
            User. Any amended conditions submitted, proposed or stipulated by the User,
            regardless of whether the Group has objected to them explicitly, are
            expressly waived and excluded.
        </h2>
        <h2>
            1.4 The Group reserves the right to amend, modify, delete, update, change
            or otherwise alter (each, a “Variation”) these Conditions at any time. The
            User is advised to check regularly for any updates, changes or
            modifications. As these Conditions will govern the access or use of the
            Services, the User should review these Conditions each time the User
            engages the Group for use of the Services. By using the Services or
            accessing the Interface, the User is deemed to accept the Conditions.
        </h2>
        <p>
            <strong>1.5</strong>
            <strong>
                The Services are not intended for use by individuals who are citizens
                or nationals of or resident in the
            </strong>
            <strong>
                United States of America, Belarus, Venezuela, Cuba, Eritrea, Cote
                d’Ivoire, Syria, Liberia, Democratic People’s Republic of Korea (North
                Korea), Democratic Republic of Congo, Iran, Libya, Somalia, South
                Sudan, Sudan and Yemen
            </strong>
            <strong>
                (each a “Prohibited Jurisdiction”) or any member of the Sanctions List
                (collectively, the “Prohibited User”). Any Prohibited User accessing
                the Interface and/or Services must immediately cease to use the
                Interface and the Services.
            </strong>
        </p>
        <p>
            <strong>1.6</strong>
            <strong>
                It is important that the User understands the Applicable Laws that
                apply in their country in relation to the Services before using the
                Services. Use of the Services may not be permissible in all countries
                where this Interface may be accessed or viewed. The User must abide by
                the Applicable Laws and in the event that use of the Services is not
                permitted, the User must leave the Interface immediately. If the User
                has any doubt as to the legal position in their jurisdiction, the User
                should consult with the relevant authorities in their jurisdiction
                prior to using the Services.
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>
                1.7 The Group cannot advise on the current legal position in any
                country with respect to the Interface and Services and will not be held
                responsible for the consequences of the User’s use of the Interface for
                any purposes whatsoever. Accordingly, by using the Interface, the User
                expressly indemnifies the Group from any legal consequences and/or
                claims for damages whatsoever, arising from the User’s use of the
                Services or Interface.
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>1.8 </strong>
            <strong>Risk Warning on Digital Payment Token Services </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>
                The MAS requires the Group to provide this risk warning to you as a
                User or customer of a digital payment token (“DPT”) service provider. A
                DPT is any digital representation of value that:
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>1. </strong>
            <strong>Is expressed as a unit;</strong>
        </p>
        <p>
            <strong>2. </strong>
            <strong>
                Is not denominated in any currency, and is not pegged by its issuer to
                any currency;
            </strong>
        </p>
        <p>
            <strong>3. </strong>
            <strong>
                Is, or is intended to be, a medium of exchange accepted by the public,
                or a section of the public, as a payment for goods or services or for
                the discharge of a debt;
            </strong>
        </p>
        <p>
            <strong>4. </strong>
            <strong>Can be transferred, stored, or traded electronically; and</strong>
        </p>
        <p>
            <strong>5. </strong>
            <strong>
                Satisfies such other characteristics as the MAS may prescribe
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>
                Before you pay your DPT service provider any money or DPT, you should
                be aware of the following:
            </strong>
        </p>
        <p>
            <strong>1. </strong>
            <strong>
                Please note that you may not be able to recover all the money or DPTs
                you paid to your DPT service provider if your DPT service provider’s
                business fails.
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>2. </strong>
            <strong>
                You should not transact in the DPT if you are not familiar with this
                DPT. Transacting in DPTs may not be suitable for you if you are not
                familiar with the technology that DPT services are provided.
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>3. </strong>
            <strong>
                You should be aware that the value of DPTs may fluctuate greatly. You
                should buy DPTs only if you are prepared to accept the risk of losing
                all of the money you put into such tokens.
            </strong>
        </p>
        <p>
            <strong>Notice for non-Singapore residents </strong>
        </p>
        <p>
            <strong>
                Please note that you may not be able to recover all the money you paid
                to Nogle Singapore if Nogle Singapore’s business fails.
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>
                By downloading, installing, or using the Nogle Singapore’s mobile
                application and/or any associated software supplied by the Group (where
                applicable), you expressly acknowledge and agree to be bound by the
                terms of these Conditions, the Privacy Policy, the Acceptable Use
                Policy or such other additional terms and conditions implemented from
                time to time by the Group.
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>
                The terms of these Conditions, the Privacy Policy, the Acceptable Use
                Policy or such other additional terms and conditions shall be
                collectively referred to as the Legal Documents.
            </strong>
            <strong> </strong>
            <strong>
                The Group may amend any of the Legal Documents from time to time. Such
                revised Legal Documents shall be effective as published on Nogle
                Singapore’s website or such other means as the Group may determine.
            </strong>
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            <strong>
                Your continued use of Nogle Singapore’s platform and any associated
                software constitutes your consent and acceptance of the revised Legal
                Documents. If you do not agree to any of the terms and conditions of
                the Legal Documents, you must stop using Nogle Singapore’s platform,
                its associated software and its services and terminate your
                relationship with the Group.
            </strong>
        </p>
        <p></p>
        <p></p>
        <h1>
            2. <u>DEFINITIONS AND INTERPRETATIONS</u>
            <u></u>
        </h1>
        <p></p>
        <h2>
            2.1 In these Conditions, unless the context otherwise requires, the
            following words or expressions shall have the following meanings
            respectively:
        </h2>
        <p>
            “<strong>Affiliate</strong>” means an entity directly or indirectly
            controlled by or under common control with Nogle (Singapore) Pte. Ltd.
            where control means the possession, directly or indirectly, of the power to
            direct or cause the direction of the management and policies of such
            person, whether through the ownership of voting securities, by contract, or
            otherwise, including a Subsidiary or a Holding Group of Nogle (Singapore)
            Pte. Ltd. or any other Subsidiary of that Holding Group;
        </p>
        <p>
            “<strong>Additional Terms</strong>” has the meaning ascribed to it in
            Clause 3.2;
        </p>
        <p>
            “<strong>AML Laws</strong>” means all laws applicable to the Parties
            prohibiting money laundering or attempts to conceal or disguise the
            identity or origin of, change the form of, move, transfer, transport,
            illegal proceeds, funds or property;
        </p>
        <p>
            “<strong>Anti-Bribery Laws</strong>” means all laws applicable to the
            Parties prohibiting the bribery of government officials or any persons,
            kickbacks, inducements and other forms of commercial corruption or bribery;
        </p>
        <p>
            “<strong>Applicable Laws</strong>” means all laws applicable to the
            Parties;
        </p>
        <p>
            “<strong>Business Day</strong>” means a day that is regarded as a business
            day in Singapore;
        </p>
        <p>
            “<strong>Content</strong>” shall have the meaning ascribed to it in Clause
            18;
        </p>
        <p>
            “<strong>Control</strong>” means the holding or possession of the
            beneficial interest in or the ability to exercise the voting rights
            applicable to shares or other securities in, any entity (whether directly
            or indirectly) which confers an aggregate on the holders thereof of more
            than 50% of the total voting rights exercisable at general meetings of that
            entity. “<strong>Controlled</strong>” shall be construed accordingly;
        </p>
        <p>
            “<strong>CRS</strong>” means the common reporting standard or the Standard
        for Automatic Exchange of Financial Account Information (“    <strong>AEOI</strong>”);
        </p>
        <p>
            “<strong>CTF Laws</strong>” means all counter-terrorist financing laws;
        </p>
        <p>
            “<strong>Digital Assets</strong>” means cryptocurrencies, tokens, DPTs,
            e-money or other
        </p>
        <p>
            digital assets;
        </p>
        <p>
            “<strong>Dispute</strong>” shall have the meaning ascribed to it in Clause
            32.2;
        </p>
        <p>
            "<strong>Encumbrance</strong>" means any mortgage, assignment, debenture,
            lien, hypothecation, charge, pledge, title retention, right to acquire,
            security interest, option, pre-emptive or other similar right, right of
            first refusal, restriction, third-party right or interest, any other
            encumbrance, condition or security interest whatsoever or any other type of
            preferential arrangement (including without limitation, a title transfer or
            retention arrangement) having similar effect, or an agreement, arrangement
            or obligation to create any of the foregoing;
        </p>
        <p>
            “<strong>Event of Default</strong>” shall have the meaning ascribed to it
            in Clause 13.2;
        </p>
        <p>
            “<strong>FATCA</strong>” means the United States Foreign Account Tax
            Compliance Act;
        </p>
        <p>
            “<strong>Fee Schedule</strong>” means the schedule of transaction fees
            payable to the Group by the User in relation to the Services;
        </p>
        <p>
            “<strong>Fiat Currencies</strong>” means any currency regarded by any
            government as legal tender and “<strong>Fiat “Currency</strong>” shall be
            construed accordingly;
        </p>
        <p>
            “<strong>Force Majeure Event</strong>” means
            all causes, foreseen or unforeseen, that are beyond the reasonable control
            of the affected Party, including but not limited to (a) hacker attacks, or
            the invasion or outbreak of malware such that the computer system or any
            hardware or software associated with the running of the Interface is
            damaged or unable to perform or operate normally; (b) interruption, failure
            or disruption in electricity provision, internet connection, telephone or
            other communication system or electronic or mechanical equipment; (c)
            strikes, fire, flood or other acts of God; and (d) the regulations of any
        applicable or relevant international organisation or government authority;    <strong></strong>
        </p>
        <p>
            "<strong>Holding Group</strong>" means, in relation to a company or
            corporation, any other company or corporation in respect of which it is a
            Subsidiary;
        </p>
        <p>
            “<strong>Indemnified Parties</strong>” shall have the meaning ascribed to
            it in Clause 14;
        </p>
        <p>
            “<strong>Indemnified Party</strong>” shall have the meaning ascribed to it
            in Clause 14;
        </p>
        <p>
            “<strong>Insolvency Event</strong>” shall have the meaning ascribed to it
            in Clause 13.2.7;
        </p>
        <p>
            “<strong>KYC Process</strong>” has the meaning ascribed to it in Clause
            4.1;
        </p>
        <p>
            “<strong>Legal Disclaimers</strong>” means the Group’s legal disclaimers as
            set out in the <u>Nogle Singapore</u> <u>Legal Disclaimers</u> that forms
            part of these Conditions;
        </p>
        <p>
            “<strong>Losses</strong>” shall have the meaning ascribed to it in Clause
            14;
        </p>
        <p>
            “<strong>Notice</strong>” shall have the meaning ascribed to it in Clause
            22.1;
        </p>
        <p>
            “<strong>Permitted Purpose</strong>” shall have the meaning ascribed to it
            in Clause 17.1;
        </p>
        <p>
            “<strong>Policies</strong>” shall have the meaning ascribed to it in Clause
            3.1;
        </p>
        <p>
            “<strong>Prohibited Jurisdiction</strong>” has the meaning ascribed to it
            in Clause 1.5;
        </p>
        <p>
            “<strong>Prohibited Use</strong>” has the meaning ascribed to it in Clause
            9.2;
        </p>
        <p>
            “<strong>Prohibited User</strong>” has the meaning ascribed to it in Clause
            1.5;
        </p>
        <p>
            “<strong>Privacy Policy</strong>” means the privacy policy of the Group as
            amended from time to time;
        </p>
        <p>
            "<strong>Representatives</strong>" means, in relation to a Party hereto,
            its directors, officers, employees, advisers, agents, or other
            representatives;
        </p>
        <p>
            “<strong>Sanctions</strong>”<strong> </strong>means the economic sanctions
            laws, regulations, embargoes, or restrictive measures administered by any
            Sanctions Authority including pursuant to the Comprehensive Iran Sanctions,
            Accountability and Divestment Act of 2010 and Iran Sanctions Act or any
            equivalent sanctions or measures imposed by any Sanctions Authority.
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            “<strong>Sanctions Authority</strong>”<strong> </strong>means:
        </p>
        <p>
            (i) the Office of Foreign Assets Control of the US Department of Treasury
            (“<strong>OFAC</strong>”) or the US generally;
        </p>
        <p>
            (ii) the United Kingdom, Her Majesty Treasury (UK) Financial Sanctions
            list.
        </p>
        <p>
            (iii) the European Union (“<strong>EU</strong>”) or any member state of the
            European Union;
        </p>
        <p>
            (iv) the United Nations (“<strong>UN</strong>”) (including any of its
            related organisations);
        </p>
        <p>
            (v) the Ministry of Finance of Japan or Japan generally;
        </p>
        <p>
            (vi) the MAS or Singapore generally; or
        </p>
        <p>
            (vii) any other authorities of jurisdictions applicable to the Parties.
        </p>
        <p>
            “<strong>Sanctions Laws</strong>” means the laws relating to Sanctions or
            the Sanctions List;
        </p>
        <p>
            “<strong>Sanctions List</strong>”<strong> </strong>means the Specially
            Designated Nationals and Blocked Person List maintained by MAS, UN, HMT,
            OFAC, or any similar list maintained by or public announcement of Sanctions
            designation made by any of the Sanctions Authorities;
        </p>
        <p>
            “<strong>Services</strong>” shall have the meaning ascribed to it in Clause
            1.1;
        </p>
        <p>
            "<strong>Subsidiary</strong>" means, with respect to any entity, any other
            entity directly or indirectly Controlled by that entity;
        </p>
        <p>
            “<strong>Surviving Provisions</strong>” means Clauses 1, 2, 3, 6, 8, 9, 11,
            12, 13, 14, 15, 16, 18, 20, 24, 25, 26, 26, 29 and 30;
        </p>
        <p>
            “<strong>target of Sanctions</strong>” shall have the meaning ascribed to
            it in Clause 16.1.11;
        </p>
        <p>
            “<strong>Tax</strong>” means any tax, levy, impost, duty or other charge or
            withholding of a similar nature (including any related penalty or
            interest);
        </p>
        <p>
            “<strong>Third Party Data</strong>” shall have the meaning ascribed to it
            in Clause 17.3;
        </p>
        <p>
            “<strong>User’s Reps and Warranties</strong>” shall have the meaning to
            ascribed to it in Clause 16.1; and
        </p>
        <p>
            “<strong>Variation</strong>” has the meaning ascribed to it in Clause 1.4.
        </p>
        <h2>
            2.2 The words "hereof", "herein", "hereon", and "hereunder", and words of
            similar import, when used in these Conditions, shall refer to these
            Conditions as a whole and not to any particular provision of these
            Conditions.
        </h2>
        <h2>
            2.3 Unless the context otherwise requires, words importing the singular
            shall include the plural and vice versa, and words importing a specific
            gender shall include the other genders (male, female or neuter).
        </h2>
        <h2>
            2.4 The headings in these Conditions are inserted for convenience only and
            shall not affect the construction of these Conditions.
        </h2>
        <h2>
            2.5 Any thing or obligation to be done under these Conditions which
            requires or falls to be done on a stipulated day, shall be done on the next
            succeeding Business Day, if the day upon which that thing or obligation to
            be done falls on a day which is not a Business Day.
        </h2>
        <h2>
            2.6 References to these Conditions include these Conditions as amended or
            supplemented in accordance with its terms. References to Clauses are to
            clauses of these Conditions.
        </h2>
        <p></p>
        <h1>
            3. <u>INCORPORATION OF OTHER TERMS</u>
            <u></u>
        </h1>
        <p>
            3.1 T
            he User acknowledges and confirms that they have read, understood and
            agreed to the following documents, as may be amended from time to time by
            the Group at its sole discretion, made available to the User on the
            Interface, which are hereby incorporated and form part of the Conditions:
        </p>
        <h2>
            3.1.1 the Group’s <u>Legal Disclaimers</u>;
        </h2>
        <h2>
            3.1.2 the <u>Fee Schedule</u>;
        </h2>
        <h2>
            3.1.3 the Group’s <u>Privacy Policy</u>;
        </h2>
        <h2>
            3.1.4 the <u>Referral Program Terms and Conditions</u>; and
        </h2>
        <p>
            <strong><em> </em></strong>
        </p>
        <h2>
            3.1.5 all other relevant terms.
        </h2>
        <p>
            (collectively, the “<strong>Policies</strong>”)
        </p>
        <p>
            3.2 In addition to the Policies, the use of specific aspects of the
            Interface or the Services may be subject to additional terms and conditions
            (“<strong>Additional Terms</strong>”), which will apply in full force and
            effect upon the Group notifying you of such Additional Terms. By continuing
            to access and use the Interface and the Services, you agree to such
            Additional Terms.
        </p>
        <p>
            3.3 In the event of any conflicts or inconsistencies between the terms of
            these Conditions and the Policies, the terms of the subsequent amendment to
            these Conditions will prevail over the provisions of these Conditions,
            while the provisions of these Conditions will prevail over the Policies.
        </p>
        <h1>
            4. <u>SETTING UP AN ACCOUNT</u>
        </h1>
        <p>
            4.1 In order to use the Services, the User will need to register for an
            account on the Interface. The Group may require the User to provide
            information satisfactory to the Group and relevant third parties that the
            User is not in breach of or at risk of being in breach of AML Laws,
            Anti-Bribery Laws, Sanctions Laws, CTF Laws, and other Applicable Laws.
            During the registration process, the User may need to submit information
            and documents in order to verify the User’s identity and for
            know-your-client checks of the Group to prevent fraud, money laundering and
        financing of terrorism, fraud or any other financial crime (“    <strong>KYC Process</strong>”). The Group may engage the assistance of
            third party service providers for the KYC Process and the User irrevocably
            grants the Group the right to share such information with the third party
            service providers concerned. The User is required to promptly provide
            whatever information requested and necessary to satisfy such due diligence
            requirements, including but not limited to a certificate permitting the
            determination of Tax residency and status under FATCA, CRS and other
            Applicable Laws.
        </p>
        <p>
            4.2 The Group shall also regularly monitor and assess activities that may
            constitute a potential breach of AML Laws, CTF Laws, Sanctions Laws,
            Anti-Bribery Laws, and other Applicable Laws. Notwithstanding the KYC
            Process, the User is required to provide further information and documents
            or conduct any activities as requested by the Group or any of its nominated
            third parties pursuant to this Clause on an ongoing basis.
        </p>
        <p>
            4.3 The Group may in its sole discretion, refuse to set up the account,
            terminate or suspend the User’s account and the User’s access to the
            Interface or limit the number of accounts the User may hold. The User’s
            access to one or more of the Services and the limits that may apply may be
            adjusted as a result of the information collected from the User.
        </p>
        <p>
            4.4 By setting up the account on the Interface, the User consents to the
            collection, use and disclosure of personal data and other information
            submitted to the Group by the Group, its Affiliates, and its third-party
            service providers for such purposes. More details relating to the treatment
            of the User’s personal data is set out in the Group’s <u>Privacy Policy</u>
            .
        </p>
        <h1>
            5. <u>SCOPE OF SERVICES</u>
        </h1>
        <p>
            5.1 The use of the Services is subject to the User setting up an account on
            the Interface, which entitles the User access to the following Services,
            subject to eligibility and certain conditions being satisfied, including:
        </p>
        <p>
            5.1.1 providing payment solutions, bill payments, merchant payments,
            multi-currency wallet with ability to hold Fiat Currency e-money and
            Digital Assets, including DPTs;
        </p>
        <p>
            5.1.2 allowing Users to exchange Fiat Currencies, convert Digital Assets to
            Fiat Currencies or other Digital Assets and Fiat Currencies to Digital
            Assets in transaction with the Group, DPT exchange;
        </p>
        <p>
            5.1.3 providing access to a platform for top-up or withdrawals through bank
            transfers of fiat currencies into e-money, and vice versa in real-time; and
        </p>
        <p>
            5.1.4 any other permitted financial services.
        </p>
        <p>
            <strong> </strong>
        </p>
        <p>
            5.2 The Group may refuse to accept the deposit, top-up currencies, e-money
            transfers, DPTs, Digital Assets provided by the User if the amount of the
            deposit or transfer or any transaction provided by the User, does not meet
            the minimum requirement or standard set by the Group from time to time or
            by applicable regulatory requirements.
        </p>
        <p>
            5.3 The Group reserves the right to vary the scope and provision of the
            Services, and may suspend or terminate any of the Services, at its
            discretion and without prior notice to the User.
        </p>
        <h1>
            6. <u>AGENCY</u>
        </h1>
        <p>
            The Group does not act as agent in the transactions effected through
            activities on the Interface. However, in some but not all of the Services,
            the Group administers and enforces contracts among parties engaged in
            activities on the Interface.
        </p>
        <h1>
            7. <u>DISCLOSURE OF INFORMATION</u><u></u>
        </h1>
        <p>
            7.1 The User represents, warrants and undertakes that all information
            provided by the User to the Group at any relevant time is true, complete,
            accurate, current and not misleading in any material respect.
        </p>
        <p>
            7.2 The User undertakes to maintain and update all such information to keep
            it true, complete, accurate, current and not misleading in any material
            respect. The User undertakes to immediately inform the Group of any
            relevant change in the information which has been provided to the Group in
            the course of the Group providing the Services.
        </p>
        <p>
            7.3 The User irrevocably undertakes to provide to the Group, promptly upon
            demand, any information and/or documentation as the Group may request from
            time to time. The User hereby expressly and irrevocably permits and
            authorises the Group and its employees and/or any other persons authorised
            by the Group to provide any information and copies of any document provided
            by the User as may be required pursuant to any KYC Process.
        </p>
        <p>
            7.4 The User shall comply with such identification, customer due diligence
            and other anti-money laundering requirements that the Group may require
            from time to time.
        </p>
        <h1>
            8. <u>ACCOUNT SECURITY</u>
            <u></u>
        </h1>
        <p>
            8.1 The User shall be fully responsible for all activities that occur under
            the designated account they are using including all information submitted
            to the Group, whether publicly posted on the Interface or privately
            transmitted.
        </p>
        <p>
            8.2 The User shall (a) immediately notify the Group of any unauthorised use
            of password or account designation or any other breach of security, and (b)
            to ensure prompt exit from the account at the end of each session. The
            Group shall not be liable for any loss or damage arising from failure to
            comply with this Clause 8.
        </p>
        <h1>
            9. <u>PROHIBITED USES</u>
        </h1>
        <h1>
            9.1 The User may not:
        </h1>
        <h1>
            9.1.1 use the Interface or Services to disguise the origin or nature of
            illicit proceeds or to transact or deal in, any illicit Fiat Currencies or
            illicit Digital Assets, funds, property, or proceeds;
        </h1>
        <p>
            <strong> </strong>
        </p>
        <h1>
            9.1.2 misuse the Interface or Services, as a result of which any Applicable
            Laws, including but not limited to AML Laws, CTF Laws, Financial Crime
            Laws, Anti-Bribery Laws, Sanctions Laws, prohibit, penalise, or sanction
            the Group, or expose the Group to any legal risks;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.3 use the Interface or Services to facilitate, approve, evade, avoid,
            or circumvent any Applicable Laws, including but not limited to AML Laws,
            CTF Laws, Financial Crime Laws, Anti-Bribery Laws and Sanctions Laws;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.4 use the Interface or Services to evade Taxes under any
            jurisdiction(s) applicable to the User, the Interface or the Services;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.5 trade on the Interface, or use any Services, with anything other than
            Fiat Currencies, Digital Assets, funds, keys or property that have been
            legally obtained by the User and that belong to the User;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.6 use the Interface or Services to interfere with or subvert the rights
            or obligations of the Group or the rights or obligations of any other
            Interface user or any other third party;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.7 transact using misleading or inaccurate information presented to the
            Interface or to the Group or take advantage of any technical glitch,
            malfunction, failure, delay, default, or security breach;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.8 use the Interface or Services to engage in conduct that is
            detrimental to the Group or to any other Interface user or any other third
            party;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.9 falsify any account, registration, exchange, or administration
            details provided to the Group;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.10 falsify or materially omit any information or provide misleading or
            inaccurate information requested by the Group, including at registration or
            during the course of administering any Services to you;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.11 reverse-engineer, decompile, or disassemble any software running on
            the Interface;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.12 cause injury to, or attempt to harm the Group or any third party
            through the User’s access to the Interface or the Services;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.13 have more than one account for each Fiat Currency supported by the
            Interface;
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.14 where the User is subject to prohibitions or restrictions under
            these Conditions, access the Interface or use any Services utilising any
            virtual private network, proxy service, or any other third party service,
            network, or product with the effect of disguising the User’s IP address or
            location, or access the Interface or use the Services in a form that would
            be subject to the jurisdiction of any Prohibited Jurisdiction or government
            or government official thereof; or,
        </h1>
        <h1>
        </h1>
        <h1>
            9.1.15 violate, cause a violation of, or conspire or attempt to violate or
            cause another party to violate these Conditions or Applicable Laws.
        </h1>
        <h1>
            9.2 Any use as described in Clause 9.1 shall constitute a "Prohibited Use".
            If the Group determines that the User has engaged in any Prohibited Use,
            the Group may address such Prohibited Use through an appropriate sanction,
            in its sole and absolute discretion. Such sanction may include, but is not
            limited to, making a report to any government, law enforcement, or other
            authorities, without providing any notice to the User about any such
            report; suspending or terminating the User’s access to any Services. The
            Group may, at its sole and absolute discretion, take any action it deems
            appropriate to comply with Applicable Laws or the laws of any other
            jurisdiction. In addition, should the User’s actions or inaction result in
            the imposition of economic costs to the Group, the User shall pay an amount
            to the Group so as to render the Group whole, including without limitation,
            the amount of taxes or penalties that might be imposed on the Group.
        </h1>
        <h1>
            <u> </u>
        </h1>
        <h1>
            10. <u>RIGHTS OF </u><u>THE GROUP</u>
        </h1>
        <h2>
            10.1 The Group reserves the right at any time and from time to time, in its
            sole discretion, to modify or discontinue, temporarily or permanently, the
            Services (or any part thereof, including the transmission of any related
            materials or documentation) with or without notice. The Group further
            reserves the right at any time and from time to time, in its sole
            discretion, to change or terminate without notice any content or features
            contained on the Interface. The Group shall not be liable to the User or to
            any third party for any modification, suspension, or discontinuance of the
            Services, content, or features provided on the Interface.
        </h2>
        <h2>
            10.2 The Group reserves the right to refuse to process or to cancel any
            pending transaction conducted pursuant to the Services at its sole
            discretion or as required by law or in response to a court order or other
            binding government order or to enforce transaction limits. The Group cannot
            reverse a transaction that has been broadcast to the digital currency
            network. The Services are available only in connection with those Digital
            Assets that the Group, in its sole discretion, decides to support. The
            Digital Assets that the Group supports may change from time to time. The
            Group assumes no liability in connection with any attempt to use the
            Services for Digital Assets that the Group does not support.
        </h2>
        <h2>
            10.3 The systems used by the Group for its Services and the Interface may
            encounter technical limitations or other limitations, and computer and
            communications hardware systems might experience interruptions. Further,
            the Group may continually enhance and improve these systems in order to
            accommodate the level of use of the Interface. Furthermore, the Group may
            add additional features and functionality to its Services and/or Interface
            that might result in the need to develop or license additional
            technologies. Increased utilisation of the Services or the Interface or
            increased transaction volume through its processing systems or providing
            new features or functionality may cause unanticipated system disruptions,
            slower response times, degradation in levels of customer service, and
            delays in reporting accurate financial information. The Group shall in no
            circumstances be liable for any loss in respect thereto.
        </h2>
        <p>
            10.4 It may, in certain circumstances, be necessary for the Group to
            retrieve certain information from offline storage in order to execute a
            transaction pursuant to the Services. The User acknowledges and agrees that
            a transaction facilitated by the Group may be delayed.
        </p>
        <p>
            10.5 The Group reserves the right to impose transaction limits upon the
            User at its sole discretion in accordance with the terms of these
            Conditions.
        </p>
        <h1>
            11. <u>PAYMENT</u>
            <u>S TO THE GROUP</u>
        </h1>
        <p>
            11.1 The Group reserves the right to levy service fees, transaction fees,
            or fees in relation to any dormant account on Users. Such service fees may
            be adjusted from time to time and the details of any such service fees or
        transaction fees shall be set out in the <u>Fee Schedule</u>.    <strong></strong>
        </p>
        <p>
            <strong></strong>
        </p>
        <p>
            11.2 Subject to all Applicable Laws, any payments in Fiat Currencies to be
            made by the User for the Services shall be made to the Group in such Fiat
            Currencies as shall be advised by the Group to the User from time to time.
        </p>
        <p>
            11.3 If the User fails to make a payment when that payment is due and
            payable, an interest rate of 12 per cent per annum will apply to the
            outstanding amount until the default is remedied.
        </p>
        <h1>
            12. <u>TAXES</u>
            <u></u>
        </h1>
        <p>
            12.1 It is the User’s sole responsibility to determine whether, and to what
            extent, any Taxes apply to any transactions the User conducts by utilising
            the Services, and to withhold, collect, report and remit the correct
            amounts of Taxes to the appropriate Tax authorities.
        </p>
        <p>
            12.2 If the User or any other person (whether or not a party to, or on
            behalf of a party to, these Conditions) must, as required by the Applicable
            Laws, at any time deduct or withhold any Tax or other amount from any sum
            paid or payable by, or received or receivable from, the User under these
            Conditions, the User shall pay such additional amount as is necessary to
            ensure that the Group receives the sum on the due date and retains (free
            from any liability other than tax on its own overall net income) a net sum
            equal to what it would have received and so retained had no such deduction
            or withholding been required or made.
        </p>
        <p>
            12.3 The User shall (a) pay and, (b) within three (3) Business Days of
            demand, indemnify the Group against any cost, loss or liability that the
            Group (in its absolute discretion) determines has been suffered (directly
            or indirectly) by the Group for or on account of Tax in relation to a
            payment received or receivable (or any payment deemed to be received or
            receivable) under these Conditions.
        </p>
        <h1>
            13. <u>TERM AND TERMINATION</u>
        </h1>
        <p>
            13.1 The term of these Conditions shall commence on the date that these
            Conditions are accepted by the User and terminate upon:
        </p>
        <h2>
            13.1.1 the mutual agreement in writing by the User and the Group to
            terminate these Conditions; or
        </h2>
        <h2>
            13.1.2 the unilateral termination by the Group (i) upon the occurrence of
            an Event of Default (as defined below), or (ii) if so decided by the Group
            acting in its absolute discretion.
        </h2>
        <p>
            13.2 For the purposes of these Conditions,
            “<strong>Event of Default</strong>” means any one of the following events:
        </p>
        <h2>
            13.2.1 any default by the User to pay on the due date any amount payable
            pursuant to these Conditions in the mode of payment in which it is
            expressed to be payable unless:
        </h2>
        <p>
            (a) the default to pay is caused by administrative or technical error; and
        </p>
        <p>
            (b) payment is made within five (5) Business Days of its due date;
        </p>
        <h2>
            13.2.2 any default by the User in the performance or observance of any
            covenant or agreement contained in these Conditions (other than those
            referred to in Clause 13.2) and in the case of any breach which is capable
            of remedy, such breach is not remedied within three (3) Business Days or
            such other period as may be determined by the Group;
        </h2>
        <h2>
            13.2.3 if any representation or warranty made by the User in these
            Conditions is or proves to have been untrue, incorrect or misleading in any
            material respect at the time made or deemed to be made;
        </h2>
        <h2>
            13.2.4 the User suspends or ceases to carry on (or threatens to suspend or
            cease to carry on) all or a material part of its business;
        </h2>
        <h2>
            13.2.5 any event or circumstance, which in the opinion of the Group, has or
            is reasonably likely to have a material adverse effect;
        </h2>
        <h2>
            13.2.6 the User commits any fraud or act of dishonesty, and/or acts in any
            manner which is adverse to the interests of the Group;
        </h2>
        <h2>
            13.2.7 any Insolvency Event occurs in respect of the User. For the
            purpose of this Section, an "
            Insolvency Event" shall mean the occurrence of any one of the following
            events, but this Clause 13.2.7 shall not apply to any winding-up petition
            or any application which is vexatious and is discharged, stayed or
            dismissed within twenty-one (21) days of its commencement:
        </h2>
        <p>
            (a) a petition is presented or a meeting is convened for the purpose of
            considering a resolution or other steps are taken by any person with the
            view to winding up of the User or, placing the User under judicial
            management;
        </p>
        <p>
            (b) the User resolving to wind itself up or otherwise dissolve itself;
        </p>
        <p>
            (c) the appointment of a liquidator or provisional liquidator in respect of
            the User;
        </p>
        <p>
            (d) a judicial manager being appointed in respect of the User or any of the
            User’s assets;
        </p>
        <p>
            (e) the User entering into a scheme of arrangement or composition with or
            assignment for the benefit of all or any class of its creditors;
        </p>
        <p>
            (f) the appointment of a receiver or receiver and manager over the User or
            any of its assets; or
        </p>
        <p>
            (g) anything analogous or having a substantially similar effect to any of
            the events specified above happening under the law of any applicable
            jurisdiction;
        </p>
        <h2>
            13.2.8 it is or will become unlawful for the User or the Group to perform
            or comply with any one or more of its material obligations under these
            Conditions; and/or
        </h2>
        <h2>
            13.2.9 any material provision of these Conditions is or becomes for any
            reason, illegal, invalid and/or unenforceable.
        </h2>
        <p>
            13.3 The aforesaid termination shall be without prejudice to the right of
            the Group in respect of any antecedent breach of the terms in these
            Conditions and shall not affect the validity, continuance or effectiveness
            of the Surviving Provisions or any other provisions in these Conditions
            which are intended to survive such termination.
        </p>
        <p>
            13.4 The User shall notify the Group of any Event of Default (and the
            steps, if any, being taken to remedy it) promptly upon becoming aware of
            its occurrence. Promptly upon a request by the Group, the User shall supply
            to the Group a certificate signed by two of its directors or senior
            officers on its behalf certifying that no Event of Default is continuing
            (or if an Event of Default is continuing, specifying the Event of Default
            and the steps, if any, being taken to remedy it).
        </p>
        <h1>
            14. <u>IN</u>
            <u>DEMNITY</u>
            <u></u>
        </h1>
        <p>
            The User irrevocably undertakes to fully indemnify and hold harmless each
            the Group, its shareholders, officers, directors, employees and agents and
        their respective successors, heirs and assigns (collectively, the “<strong>Indemnified Parties</strong>” and each an “    <strong>Indemnified Party</strong>”) promptly upon demand at any time and
            from time to time, from and against any and all losses, claims, actions,
            proceedings, damages, demands, judgments, sums payable, liabilities,
            damages, costs, charges and expenses (including reasonable attorneys’ fees
            and disbursements), and/or expenses (collectively, “<strong>Losses</strong>
            ”) to which any Indemnified Party may become subject, insofar as such
            Losses arise out of, or in any way relate to, or result from:
        </p>
        <h2>
            (a) the information provided by the User;
        </h2>
        <h2>
            (b) the breach of any of the User’s obligations, undertakings,
            representations and warranties, the provisions of these Conditions, or the
            provisions of any agreements entered into by the User or its Affiliates in
            relation to the Services; or
        </h2>
        <p>
            <strong><em> </em></strong>
        </p>
        <h2>
            (c) any breach or non-compliance of the User with any Applicable Laws,
            along with any regulations or notices.
        </h2>
        <h1>
            15. <u>EXCLUSION OF LIABILITY</u>
            <u></u>
        </h1>
        <p>
        </p>
        <p>
            The Group (to the fullest extent permitted by law) shall not in any
            circumstances be liable for any loss or damage including any direct,
            indirect, special, consequential, punitive, incidental loss or damage
            (whether arising in tort, contract or otherwise) incurred by the User, its
            shareholders, officers, directors, employees and agents, arising out of or
            in relation to or in connection with these Conditions including but not
            limited to:
        </p>
        <h2>
            (a) the provision of the Services by the Group to the User, including any
            investment losses incurred by the User pursuant to its use of the Services;
        </h2>
        <h2>
            (b) the use or accuracy of the Interface;
        </h2>
        <h2>
            (c) any information contained in any third party materials or on any third
            party sites that may be accessible or linked to the Interface;
        </h2>
        <h2>
            (d) the content of and/or use of any agreement entered into by the User
            pursuant to the Services, in its original form as provided by the Group, or
            in such other form as may be negotiated and agreed upon, including any
            disputes as may arise between the User and any third parties relating to
            such agreements;
        </h2>
        <h2>
            (e) non-compliance, default, action or omission of a third party in respect
            of any agreement entered into by the User pursuant to the Services;
        </h2>
        <h2>
            (f) any loss (whether direct or indirect) of profits, business, business
            opportunities, revenue, turnover, reputation or goodwill;
        </h2>
        <h2>
            (g) lost or damaged data;
        </h2>
        <h2>
            (h) damage to or interruption of any local area networks;
        </h2>
        <h2>
            (i) the introduction of viruses, worms or other destructive programs or any
            other commercial or economic losses;
        </h2>
        <h2>
            (j) any decision made or action taken by any party in reliance on the
            Services (or its results or the Interface contents);
        </h2>
        <h2>
            (k) any delay, failure interruption or corruption of any data or other
            information transmitted in connection with use of the Services; and
        </h2>
        <h2>
            (l) any inaccuracies, errors or omissions in connection with the Services
            and any Interface content.
        </h2>
        <h1>
            16. <u>REPRESENTATIONS AND WARRANTIES</u>
            <u></u>
        </h1>
        <h2>
            16.1 The User makes the following representations
            and warranties to the Group:
        </h2>
        <h2>
            16.1.1 the person completing the application to register the User as a
            member of the Interface is duly authorised by the User to do all things in
            connection to the use of the Interface;
        </h2>
        <h2>
            16.1.2 any factual information provided by the User for the purposes of
            accessing and using the Services or otherwise is true, complete, accurate,
            not misleading in any material respect, and does not omit any material
            fact, the omission of which would make any fact or statement therein
            misleading as at the date it was provided or deemed to have been provided
            or as at the date (if any) at which it is stated;
        </h2>
        <h2>
            16.1.3 the User is at least 18 years old (or has otherwise attained the age
            of majority in the User’s jurisdiction), has full capacity to enter into
            such contracts (including entering into the transactions on the Interface),
            and these Conditions are valid and enforceable against the User;
        </h2>
        <h2>
        </h2>
        <h2>
            16.1.4 the User understands the risks associated with using the Interface
            and Services and is not a Prohibited User or otherwise prohibited by
            Applicable Laws from using the Services;
        </h2>
        <h2>
            16.1.5 that any trading or other instructions received or undertaken
            through the User’s login credentials or from the User’s authorised e-mail
            address on file with the Group are deemed to be valid, binding, and
            conclusive, and that the Group may act upon those instructions without any
            liability or responsibility attaching to it;
        </h2>
        <h2>
        </h2>
        <h2>
            16.1.6 the User will not use the Interface or Services in order to conceal
            or disguise the origin or nature of proceeds that are illegal or may result
            in or further any breach of Applicable Laws, including AML Laws or CTF
            Laws, or to deal in any unlawful Digital Assets, unlawful Fiat Currencies,
            property, funds, or proceeds;
        </h2>
        <h2>
            16.1.7 the User will not trade or use any Services using anything other
            than Fiat Currencies, Digital Assets or other property that has been
            legally obtained by the User and belongs to the User;
        </h2>
        <h2>
            16.1.8 that the User is, at its own cost and expense, currently in
            compliance with all Applicable Laws that relate to or affect the Services
            conducted under these Conditions, including but not limited to AML Laws,
            CTF Laws, Financial Crime Laws, Anti-Bribery Laws, Sanctions Laws, or Tax
            laws, including FATCA and CRS;
        </h2>
        <h2>
            16.1.9 that the User consents to any and all Tax and information reporting
            under AML Laws, CTF Laws, Financial Crime Laws, Anti-Bribery Laws,
            Sanctions Laws, or Tax laws, including FATCA and CRS, as the Group may
            reasonably determine;
        </h2>
        <h2>
            16.1.10that neither the User nor any of the User’s Affiliates has directly
            or indirectly offered, promised, given, or authorised any payment, or
            offered, promised, given, or authorised the giving of anything else of
            value, including any Digital Assets, to a government official or individual
            employed by another entity in the private sector in violation of any
            applicable Anti-Bribery Laws;
        </h2>
        <h2>
        </h2>
        <h2>
            16.1.11neither the User, or, in the event that the User is a company, any
            of its Affiliates, or to its knowledge, joint ventures or any director,
            officer, agent, employee or any other person acting on any of its behalf,
        </h2>
        <p>
            (a) is currently:
        </p>
        <p>
            (i) subject to any Sanctions or listed on or owned or controlled by a
            person listed on, or acting on behalf of a person listed on any Sanctions
            List;
        </p>
        <p>
            (ii) located in, incorporated under the laws of, or owned or (directly or
            indirectly) controlled by or acting on behalf of a person located in or
            organised under the laws of a country or territory that is the target of
            country wide or territory wide Sanctions; or
        </p>
        <p>
        (iii) otherwise a target of Sanctions (where a “    <strong>target of Sanctions</strong>” signifies a person with whom a US
            person or other citizen or national of a sanctions authority would be
            prohibited or restricted by law from engaging in trade, business or other
            activities);
        </p>
        <p>
            (b) has received notice of or is aware of any claim, action, suit,
            proceeding or investigation against it with respect to sanctions by any
            Sanctions Authority; or
        </p>
        <p>
            (c) is acting directly or indirectly on behalf of any person listed on any
            Sanctions List or any person subject to the jurisdiction of a Prohibited
            Jurisdiction;
        </p>
        <p>
            and none of it or, in the event that the User is a company, any of its
            Affiliates will directly or indirectly use any monies received by the User
            or any part thereof, or lend contribute or otherwise make available such
            proceeds to any subsidiary, joint venture partner or other persons or
            entity for the purpose of financing the activities of any person currently
            the subject of any Sanctions or any person subject to the jurisdiction of a
            Prohibited Jurisdiction;
        </p>
        <h2>
            16.1.12the User will fairly and promptly report all income associated with
            the User’s activity on the Interface in relation to the Services pursuant
            to Applicable Laws and pay any Tax due thereon;
        </h2>
        <h2>
            16.1.13the User has:
        </h2>
        <p>
            (a) complied in all material respects with all Tax laws in all
            jurisdictions in which it is subject to Tax and has filed all Tax returns
            and paid all Tax and other statutory payments due and payable by it and, to
            the extent any Tax is not due, has established reserves that are adequate
            for the payment of such Tax and such payments;
        </p>
        <p>
            (b) no criminal proceedings instituted against the User; and
        </p>
        <p>
            (c) complied with all Applicable Laws including applicable AML Laws, CTF
            Laws, Anti-Bribery Laws, Sanctions Laws, employment laws, rules
            authorisations issued by the government or governmmental authorities where
            it is located, its business is being operated and the personnel of the User
            are located and has taken all required permissions for the operation of its
            business, as applicable.
        </p>
        <h2>
            16.1.14in the event that the User is a company, it is a corporation duly
            incorporated and validly existing under the laws of the jurisdiction of its
            incorporation, with full power and authority to own its assets and
            properties, and to conduct its business, either directly or through its
            subsidiaries or associates, as currently conducted;
        </h2>
        <h2>
            16.1.15it has obtained all necessary corporate approvals (if required) and
            other actions to authorise the execution, delivery and performance of these
            Conditions and all such other documents and instruments as are specified or
            referred to in these Conditions (if any); and
        </h2>
        <h2>
            16.1.16its acceptance of these Conditions, its performance of its
            obligations and all transactions contemplated hereunder shall not:
        </h2>
        <p>
            (d) result in a breach of its constitutional documents or equivalent
            constitutional documents (if applicable);
        </p>
        <p>
            (e) result in an infringement, or constitute a default under, any
            directive, instrument, contract, document or agreement to which it is a
            party or by which it is bound; and
        </p>
        <p>
            (f) result in a breach of any law, rule, regulation, ordinance, order,
            judgment or decree of or undertaking to any court, government body,
            statutory authority or regulatory, administrative or supervisory body.
        </p>
        <p>
            (collectively, the “<strong>User’s Reps and Warranties</strong>”)
        </p>
        <h2>
            16.2 Each of the User’s Reps and Warranties shall survive and continue to
            have full force and effect after the execution of these Conditions, and the
            User warrants to the Group that the User’s Reps and Warranties shall be
            true and correct and fully observed at all times with reference to the
            facts and circumstances existing at all such times.
        </h2>
        <p>
            16.3 The Group makes no representations, warranties, or guarantees to the
            User of any kind. The Interface and the Services are offered strictly on an
            as-is basis and, without limiting the generality of the foregoing, are
            offered without any representation as to merchantability or fitness for any
            particular purpose.
        </p>
        <h1>
            17. <u>CONFIDENTIALITY</u>
            <u></u>
        </h1>
        <h2>
            17.1 Subject to the Group’s <u>Privacy Policy</u>, the User hereby
            expressly and irrevocably permits and authorises the Group and its
            employees and/or any other persons authorised by the Group to disclose
            all information provided by the User to the Group under these
            Conditions for any purposes as deemed fit by the Group under these
            Conditions (the “
            Permitted Purpose”), save for any information which the User expressly
            instructs in writing to be treated as confidential and has been clearly
            marked by the User as “confidential”.
        </h2>
        <p>
            17.2 The confidentiality obligation under Clause 17.1 shall not apply to:
        </p>
        <h2>
            17.2.1 any information which becomes generally known to the public, other
            than by reason of any wilful or negligent act or omission of the Group or
            any of their respective Representatives;
        </h2>
        <h2>
            17.2.2 any information which is required to be disclosed pursuant to any
            Applicable Laws or any requirement of any competent governmental or
            statutory authority or pursuant to rules or regulations of any relevant
            regulatory, administrative or supervisory body (including without
            limitation, any relevant stock exchange or securities council);
        </h2>
        <h2>
            17.2.3 any information which is required to be disclosed pursuant to any
            legal process issued by any court or tribunal; and
        </h2>
        <h2>
            17.2.4 any information disclosed by the Group to their respective bankers,
            financial advisers, consultants and legal or other advisers for the purpose
            of these Conditions.
        </h2>
        <p>
            17.3 The
            User hereby acknowledges and agrees that the information (including
            personal data) of third parties (the “<strong>Third Party Data</strong>”)
            may be disclosed by the User to the Group from time to time for the
            Permitted Purpose. Accordingly, the User agrees and undertakes to the Group
            that all necessary consents from the relevant individuals to whom such
            information/ personal data relates either have been obtained, or at the
            time of disclosure will have been obtained, for the disclosure of their
            information/ personal data to the Group, for the Group's collection, use
            and/or disclosure for the Permitted Purpose and that such consents have not
            been withdrawn.
        </p>
        <p>
            17.4 The User represents and warrants to the Group that it has at all times
            complied with and will continue to comply with the requirements of the
            Personal Data Protection Act 2012 (“<strong>PDPA</strong>”), in respect of
            the collection, use, disclosure and other handling of the Third Party Data
            and any other data obtained by the User through the Interface. The User
            shall be fully responsible, for ensuring that all requirements at law
            (whether applicable to the User and/or the Group) have been complied with.
        </p>
        <p>
            17.5 The obligations contained in this Clause 17 shall endure, even after
            the release of any Parties or termination of these Conditions in accordance
            with and as permitted by the provisions of these Conditions, without limit
            in point of time except and until any confidential information enters the
        public domain.
        </p>
        <p>
        </p>
        <h1>
            18. <u>LIMITED LICENSE</u>
        </h1>
        <p>
            The User is granted a limited, non-transferable license, subject to these
            Conditions, to use the Interface and related content, materials and
            information (collectively, the “<strong>Content</strong>”) solely for
            approved purposes as permitted by the Group from time to time. Any other
            use of the Interface, Services or Content is expressly prohibited and all
            other right, title and interest in the Interface or Services is exclusively
            the property of the Group. The User agrees that it will not copy, transmit,
            distribute, sell license, reverse engineer, modity, publish, or participate
            in the transfer or sale of, create derivative works from, or in any other
            way exploit any of the Content, in whole or in part, Nogle Singapore” and
            all logos, trade name, work marks, or design related to the Services or
            displayed on the Interface are either trade marks or registered marks of
            the Group or its Affiliate(s). The User shall not copy, imitate or use such
            logos or marks without the prior written consent of the Group.
        </p>
        <h1>
            19. <u>FURTHER ASSURANCE</u><u></u>
        </h1>
        <p>
            Each Party agrees from time to time to perform any further acts and execute
            and deliver any further documents and instruments and do or refrain from
            doing all such further acts and things as may from time to time reasonably
            be requested by the other Parties to carry out effectively or better
            evidence or perfect the true spirit, intent, meaning and purpose of these
            Conditions.
        </p>
        <h1>
            20. <u>NO PARTNERSHIP OR AGENCY</u>
        </h1>
        <p>
            Nothing in this agreement is intended to, or shall be deemed to, establish
            any partnership or joint venture between any of the Parties or constitute
            any Party the agent of another Party.
        </p>
        <h1>
            21. <u>DISPUTES BETWEEN USERS</u>
        </h1>
        <p>
            21.1 In the event that trading disputes arise between Users, the Users
            concerned shall record the particulars of the dispute and report such
            dispute to the Group in a timely manner. The Group may request that the
            Users concerned provide further information and supporting evidence in
            relation to the dispute and the Users shall comply at all times with such
            requests by the Group.
        </p>
        <p>
            21.2 When disputes arise between the Users, the Group may provide necessary
            trading data or other information to the competent administrative or
            judicial authority pursuant to the Applicable Laws.
        </p>
        <p>
            21.3 Unless otherwise agreed by the Users or required by Applicable Laws,
            the Users shall follow the procedure stipulated under Clause 32 to resolve
            any dispute arising from the use of the Interface or Services.
        </p>
        <p>
            21.4 Once the dispute between the Users concerned has been settled or the
            Users have received final and affirmative award or judgment, the Users
            concerned shall provide evidence of such settlement agreement, award, or
            judgment to the Group.
        </p>
        <h1>
            22. <u>NOTICES</u><u></u>
        </h1>
        <h2>
            22.1 Any notice or other communication in connection with these
            Conditions (each, a “
            Notice”) shall be: (a) in writing; and (b) delivered by hand, electronic
            mail or registered post. All Notices shall be delivered to the Party’s
            address, and each Party may designate by notice in writing to the other a
            new address to which Notices shall be given.
        </h2>
        <h2>
            22.2 A Notice shall be effective upon receipt and shall be deemed to have
            been received: if delivered by hand or registered post, at the time of
            delivery; or if delivered by e-mail, at the time that it is received in
            recipient’s inbox.
        </h2>
        <h1>
            23. <u>SEVERABILITY</u>
            <u></u>
        </h1>
        <p>
            The illegality, invalidity or unenforceability of any provision of these
            Conditions under the law of any jurisdiction shall not affect its legality,
            validity or enforceability under the law of any other jurisdiction nor the
            legality, validity or enforceability of any other provision.
        </p>
        <h1>
            24. <u>INVALIDITY</u>
            <u></u>
        </h1>
        <p>
            24.1 If any provision in these Conditions shall be held to be illegal,
            invalid or unenforceable, in whole or in part, the provision shall
            apply with whatever deletion or modification is necessary so that the
            provision is legal, valid and enforceable and gives effect to the
            commercial intention of the Parties.
        </p>
        <p>
            24.2 To the extent it is not possible to delete or modify the provision, in
            whole or in part, under Clause 24.1, then such provision or part of it
            shall, to the extent that it is illegal, invalid or unenforceable, be
            deemed not to form part of these Conditions and the legality, validity and
            enforceability of the remainder of these Conditions shall, subject to any
            deletion or modification made under Clause 24.1, not be affected.
        </p>
        <h1>
            25. <u>REASONABLENESS</u>
            <u></u>
        </h1>
        <p>
            Each of the Parties confirms that it has the opportunity to seek
            independent legal advice relating to all the matters provided for in these
            Conditions and agrees that (a) there shall be no presumption that any
            ambiguity in these Conditions should be construed in favour of or against
            any Party solely as a result of such Party’s actual or alleged role in the
            drafting of these Conditions, and (b) the provisions of these Conditions
            and all documents entered into pursuant to these Conditions are fair and
            reasonable.
        </p>
        <h1>
            26. <u>ENTIRE AGREEMENT</u>
            <u></u>
        </h1>
        <p>
            These Conditions constitute the whole agreement between the Parties
            relating to its subject matter and (save as otherwise provided)
            supersede and extinguish any prior drafts, agreements, undertakings,
            representations, warranties, assurances and arrangements of any nature,
            whether in writing or oral, relating to such subject matter.
        </p>
        <p>
            <strong> </strong>
        </p>
        <h1>
            27. <u>FORCE MAJEURE</u>
        </h1>
        <h1>
            The Group shall not be liable for any failure, delay or variation in the
            performance of its obligations under these Conditions if such failure,
            delay or variation is caused by any Force Majeure Event.
        </h1>
        <h1>
            <u> </u>
        </h1>
        <h1>
            28. <u>NO ASSIGNMENT</u><u></u>
        </h1>
        <p>
            The User may not assign or transfer all or part of his rights or
            obligations under these Conditions.
        </p>
        <h1>
            29. <u>VARIATIONS</u>
            <u></u>
        </h1>
        <p>
            29.1 The Group reserves the right at any time in its absolute discretion to
            make any variation to any provisions of these Conditions, without prior
            notice, and the User shall be bound by such variations from such date that
            such variation is directed by the Group to take effect.
        </p>
        <p>
            29.2 Unless expressly agreed, no Variation shall constitute a general
            waiver of any provisions of these Conditions, nor shall it affect any
            rights, obligations or liabilities under or pursuant to these Conditions
            which have already accrued up to the date of variation, and the rights and
            obligations of the parties hereto under or pursuant to these Conditions
            shall remain in full force and effect, except and only to the extent that
            they are so varied.
        </p>
        <h1>
            30. <u>REMEDIES AND WAIVER</u>
            <u></u>
        </h1>
        <p>
            No failure on the part of any Party to exercise, and no delay on its part
            in exercising, any right or remedy under these Conditions will operate as a
            release or waiver thereof, and any single or partial exercise of any right
            or remedy shall not preclude any other or further exercise thereof or the
            exercise of any other right or remedy. The rights provided in these
            Conditions are cumulative and not exclusive of any rights or remedies
            provided by law.
        </p>
        <h1>
            31. <u>NO RIGHTS OF THIRD PARTIES</u>
            <u></u>
        </h1>
        <p>
            No term of these Conditions is enforceable by a person who is not a Party
            to these Conditions.
        </p>
        <h1>
            32. <u>GOVERNING LAW</u>
            <u> AND JURISDICTION</u>
            <u></u>
        </h1>
        <p>
            <strong> </strong>
        </p>
        <p>
            32.1 These Conditions
            shall be governed by, and construed in accordance with, the laws of
            Singapore.
        </p>
        <p>
        32.2 If any dispute or difference of any kind whatsoever (a "        <strong>Dispute</strong>") shall arise between the Parties under or in
                connection with these Conditions, or the existence, breach, termination
                or validity hereof, the Parties shall attempt, for a period of thirty
                (30) days after the receipt of written notice from a Party of the
                existence of the Dispute by the other Party, to settle such Dispute in
                the first instance by mutual discussion between the Parties.
        </p>
        <p>
            32.3 If the Dispute cannot be settled within thirty (30) days of such
            notice issued pursuant to Clause 32.2 above, the Parties agree that any
            such a Dispute shall be referred to and finally resolved by arbitration
            administered by the Singapore International Arbitration Centre in
            accordance with the Arbitration Rules of Singapore International
            Arbitration Centre for the time being in force, which rules are deemed to
            be incorporated by reference in this Clause. The seat of the arbitration
            shall be in Singapore and the language of the arbitration shall be English.
            The tribunal for the arbitration shall consist of one (1) arbitrator.
        </p>
      </div>
    </div>
  );
}
